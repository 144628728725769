import { Typography, Button, Box, Grid, Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Contactform from './Contactform';

import '../App.css';


const ScrollAnimation = () => {

    return (
        <Box className="containerMain">

            <div className="App">
                <main>
                    <div>
                        <Box className="heroWrapper section" id="start">
                            <Box className='heroWrapperLayer'></Box>
                            <Container maxWidth="md" className="containerHero" /*sx={{width: 300,backgroundColor: 'success.main',}}*/>
                                <Box className="HeroBox">
                                    <img src="BB-Wappen.png" className='HeroLogo' />
                                    <Typography variant='h1' align='center' color="textPrimary" gutterBottom>Brandenburger Notarbund e.V.</Typography>
                                    <div>
                                        <Button href="/#kontakt" variant='contained' className='heroCTA'>
                                            Kontakt
                                        </Button>
                                    </div>
                                </Box>
                            </Container>
                        </Box>



                        <Box className="mainWrapper">



                            <Container maxWidth="md" className="container subheroContainer">
                                <Box className="textBox">
                                    <Box className="textBoxInner">
                                        <Typography variant='h3' className='subtitleH3'>Willkommen</Typography>
                                        <Typography variant='body1'>
                                            <p>Der Brandenburger Notarbund vertritt die Interessen der Notare im Bundesland Brandenburg. Wir setzen uns für berufliche und wirtschaftliche Belange ein, bieten umfangreiche Beratung und Unterstützung und fördern die kontinuierliche Fortbildung unserer Mitglieder. Qualitätssicherung und Öffentlichkeitsarbeit sind ebenso zentrale Aufgaben wie die Vernetzung und der Austausch unter den Notaren.</p>
                                        </Typography>
                                    </Box>
                                </Box>
                            </Container>




                            <Box className="bannerSection section" id="quote">
                                
                            <Container maxWidth="md" className="container bannerContainer">

                                    <Box className="quoteInnerBox">
                                        <img src="quote-white.svg" className='quoteIcon' />
                                        <Box>
                                            <Typography variant='body1' className='quoteText'>
                                                Der Notar ist ein unparteiischer Berater in Rechtsangelegenheiten und trägt wesentlich zur Rechtssicherheit und zum Rechtsfrieden bei.
                                            </Typography>
                                        </Box>
                                        <img src="quote-white.svg" className='quoteIcon end' />
                                    </Box>

                                </Container>
                            </Box>




                            <Container maxWidth="md" className="container section" id="notare">
                                <Typography variant='h2' className='sectionTitle'>
                                Brandenburger Notarbund e.V.
                                </Typography>

                                <Typography variant='body1' paragraph>
                                                <p>Der Brandenburger Notarbund ist eine bedeutende Organisation, die die Interessen der Notare im Bundesland Brandenburg vertritt. Seine Hauptaufgaben umfassen die Interessenvertretung, Beratung und Unterstützung der Mitglieder, Fortbildung und Schulung, Qualitätssicherung, Öffentlichkeitsarbeit sowie die Förderung der kollegialen Vernetzung.</p>
    
    <p>Der Notarbund setzt sich aktiv für die beruflichen und wirtschaftlichen Interessen der Notare in Brandenburg ein. Dies beinhaltet die Vertretung gegenüber politischen Institutionen, der Justiz und anderen relevanten Organisationen. Durch umfangreiche Beratung und Unterstützung hilft der Bund seinen Mitgliedern bei rechtlichen, organisatorischen und finanziellen Fragen.</p>
    
    <p>Ein wichtiger Schwerpunkt des Brandenburger Notarbunds liegt in der Fort- und Weiterbildung seiner Mitglieder. Regelmäßig werden Seminare, Workshops und Schulungen organisiert, um sicherzustellen, dass die Notare stets auf dem neuesten Stand der rechtlichen Entwicklungen, Gesetzesänderungen und Best Practices sind.</p>
    
    <p>Zur Gewährleistung hoher Qualitätsstandards in der notariellen Arbeit führt der Notarbund Qualitätskontrollen durch und bietet Unterstützung bei der Umsetzung von Qualitätsmanagementsystemen. Die Öffentlichkeitsarbeit des Bundes zielt darauf ab, das Bewusstsein für die wichtige Rolle der Notare in der Gesellschaft zu stärken und die Öffentlichkeit über die Aufgaben und Leistungen von Notaren zu informieren.</p>
    
    <p>Darüber hinaus fördert der Brandenburger Notarbund den Austausch und die Vernetzung unter den Notaren durch regelmäßige Treffen, Konferenzen und andere Veranstaltungen. Diese Plattformen ermöglichen es den Mitgliedern, sich auszutauschen und voneinander zu lernen, um ihre berufliche Praxis kontinuierlich zu verbessern.</p>
                                                </Typography>
                            </Container>


                            <Container maxWidth="md" className="container section" id="kontakt">
                                <Typography variant='h2' className='sectionTitle'>
                                    Kontaktieren Sie uns!
                                </Typography>
                                <Contactform />

                            </Container>

                        </Box>

                    </div>
                </main >
            </div >

        </Box>
    );
};

export default ScrollAnimation;