import * as React from 'react';
import { Box, Container, Typography } from '@mui/material';
import CookieConsent from "react-cookie-consent";
import { HashLink } from 'react-router-hash-link';

export default function Footer() {
    return (
        <Box className="Footer">
            <Container maxWidth="md" className="container FooterContainer">
                <Box className="FooterLeft">
                    <img src="BB-Wappen.png" className='brandLogoFooter' />
                </Box>
                <Box className="FooterMLeft">
                    <Typography align='center' className='FooterSubTitle'>Informationen</Typography>
                    <HashLink className='FooterLink' style={{ textDecoration: 'none' }} smooth to="/#kontakt">Kontakt</HashLink>                   
                    <HashLink className='FooterLink' style={{ textDecoration: 'none' }} smooth to="/Impressum#">Impressum</HashLink>        
                    <HashLink className='FooterLink' style={{ textDecoration: 'none' }} smooth to="/Datenschutz#">Datenschutz</HashLink>        
                </Box>
                {/* <Box className="FooterMRight">
                    <Typography align='center' className='FooterSubTitle'>Öffnungszeiten</Typography>
                    <Typography className="FooterText">Mo 9 - 12 & 13 - 17 Uhr</Typography>          
                </Box> */}
                <Box className="FooterRight">
                    <Typography align='center' className='FooterSubTitle'>Kontakt</Typography>
                    <Typography className="FooterText">Brandenburger Notarbund e.V.</Typography>  
                    <Typography className="FooterText">Dortustraße 71</Typography>  
                    <Typography className="FooterText">14467 Potsdam</Typography>  
                    <Typography className="FooterText">info@brandenburger-notarbund.de</Typography>  
                </Box>
            </Container>
            <Container maxWidth="md" className="container FooterContainerSub">
                <Typography className="FooterText">Copyright 2024 Brandenburger Notarbund e.V.</Typography>
            </Container>

            <CookieConsent
                location="bottom"
                buttonText="Verstanden"
                cookieName="myAwesomeCookieName2"
                buttonStyle={{ color: "#ffffff", fontSize: "16px" }}
                expires={150}
            >
                <Typography variant='body1'>Diese Website verwendet nur technisch notwendige Cookies, um die Funktionalität zu gewährleisten. Es werden keine persönlichen Daten gesammelt.{" "}</Typography> 
            </CookieConsent>

        </Box>
    );
}